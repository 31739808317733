














































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import { Swiper } from "vue-awesome-swiper";
import SwiperClass, { SwiperOptions } from "swiper";

@Component
export default class XCarousel extends Vue {
  $refs!: {
    swiper: InstanceType<typeof Swiper> & HTMLDivElement;
    xCarousel: HTMLDivElement;
    xCarouselSlides: HTMLDivElement;
  };
  carousel: SwiperClass | null = null;
  wrapperStyles: any = null;
  slidesStyles: any = null;

  @Prop(String) readonly title!: string;
  @Prop({
    default: () => ({
      slidesPerView: "auto",
      centeredSlides: false,
      spaceBetween: 30,
      preloadImages: true,
      freeMode: true
    })
  })
  readonly options!: SwiperOptions;

  next() {
    if (this.carousel) {
      this.carousel.slideNext();
    }
  }

  prev() {
    if (this.carousel) {
      this.carousel.slidePrev();
    }
  }

  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.setCarouselSize);
    }
  }

  mounted() {
    window.addEventListener("resize", this.setCarouselSize, { passive: true });
  }

  onInit() {
    this.carousel = this.$refs.swiper.$swiper;
    this.setCarouselSize();
  }

  viewportSize() {
    return window.innerWidth || document.documentElement.clientWidth;
  }

  setCarouselSize() {
    if (!this.carousel) {
      return null;
    }

    const obXCarouselSize = this.$refs.xCarousel.getBoundingClientRect();
    const obSwiperSize = this.$refs.xCarouselSlides.getBoundingClientRect();
    const width = this.viewportSize() - obXCarouselSize.left;
    const height = obSwiperSize.height;

    this.wrapperStyles = {
      width: `${width}px`,
      height: `${height}px`
    };
    this.slidesStyles = { width: `${width}px` };
  }

  get slideIsFirst() {
    return this.carousel ? this.carousel.isBeginning : false;
  }

  get slideIsLast() {
    return this.carousel ? this.carousel.isEnd : false;
  }
}
