


































import { Vue, Component, Prop } from "vue-property-decorator";
import { AppModule } from "@/store/app";
import { Swiper } from "vue-awesome-swiper";
import SwiperClass from "swiper";
import XCarousel from "@/components/slides/XCarousel.vue";

@Component({
  components: {
    XCarousel
  }
})
export default class CategorySlides extends Vue {
  get categories() {
    return AppModule.eventsCategories;
  }
}
