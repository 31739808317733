









import { Vue, Component } from "vue-property-decorator";

@Component
export default class HomeBanner extends Vue {
  items: object[] = [
    {
      src: require("@/assets/images/sample/home.png")
    }
  ];
}
