






























































import { Vue, Component, Prop, Watch, Emit, Ref } from "vue-property-decorator";
import DateRange from "@/components/form/DateRange.vue";
import CategorySelect from "@/modules/events/components/CategorySelect.vue";

@Component({
  components: {
    DateRange,
    CategorySelect
  }
})
export default class HomeEventsSearch extends Vue {
  tab: any = null;
  tabItems: object[] = [
    {
      label: "events.calendar",
      is_outlet: false
    },
    {
      label: "events.outlet",
      is_outlet: true
    }
  ];
  filters: object = {
    dates: [],
    outlet: false
  };
}
