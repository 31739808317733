










































import { Vue, Component } from "vue-property-decorator";
import HomeBanner from "../components/Banner.vue";
import HomeEventsSearch from "../components/EventsSearch.vue";
import CategorySlides from "@/components/slides/CategorySlides.vue";
import ProductSlides from "@/modules/shop/components/ProductSlides.vue";
import { mdiCalendarTextOutline } from "@mdi/js";

@Component({
  components: {
    HomeBanner,
    HomeEventsSearch,
    CategorySlides,
    ProductSlides
  }
})
export default class Home extends Vue {
  iconCalendar: string = mdiCalendarTextOutline;
}
