






































import { Vue, Component, Prop } from "vue-property-decorator";
import XCarousel from "@/components/slides/XCarousel.vue";
import ProductModel from "@bit/planetadeleste.shopaholic.models.product";

@Component({
  components: {
    XCarousel
  }
})
export default class ProductSlides extends Vue {
  @Prop(String) readonly title!: string;
  products: ProductModel[] = [];

  mounted() {
    this.loadProducts();
  }

  async loadProducts() {
    const obProduct = new ProductModel();
    // @ts-ignore
    obProduct.byActive().limit(10);

    const response = await obProduct.get();
    if (response) {
      this.products = response.data || response;
    }
  }
}
